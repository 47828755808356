@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/inter-v3-latin-ext-regular.11b87600.woff2") format("woff2"), url("../fonts/inter-v3-latin-ext-regular.6878fdfc.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/inter-v3-latin-ext-500.e735737f.woff2") format("woff2"), url("../fonts/inter-v3-latin-ext-500.e0c4a0e5.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/inter-v3-latin-ext-600.e2af50e5.woff2") format("woff2"), url("../fonts/inter-v3-latin-ext-600.41aeec06.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/inter-v3-latin-ext-700.adc4c6c4.woff2") format("woff2"), url("../fonts/inter-v3-latin-ext-700.ae537438.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/roboto-v27-latin-700.86adbc06.woff2") format("woff2"), url("../fonts/roboto-v27-latin-700.908fc8a1.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: DM Mono;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/dm-mono-v5-latin-ext-regular.481dc8b9.woff2") format("woff2"), url("../fonts/dm-mono-v5-latin-ext-regular.542f26a0.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: DM Mono;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/dm-mono-v5-latin-ext-500.bf024821.woff2") format("woff2"), url("../fonts/dm-mono-v5-latin-ext-500.26f2ef8f.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Alliance;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/Alliance-SemiBold.78df0502.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Literata;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/Literata-VariableFont.8a56a3f4.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Inconsolata;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/Inconsolata-VariableFont.86c3b845.woff2") format("woff2");
  font-display: swap;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.wMMM1G_initial [data-function="cell-content"]:before {
  opacity: 0;
  pointer-events: none;
  content: "";
  background-color: #e3efff;
  border-radius: 2px;
  width: 100%;
  height: 4px;
  transition: opacity .1s ease-in-out;
  position: absolute;
  top: -10px;
}

.wMMM1G_hoverTop {
  position: relative;
}

.wMMM1G_hoverTop:after {
  content: "";
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.wMMM1G_hoverTop [data-function="cell-content"]:before {
  opacity: 1;
}

.wMMM1G_hoverBottom {
  position: relative;
}

.wMMM1G_hoverBottom:after {
  content: "";
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.JK6uYG_shake {
  backface-visibility: hidden;
  perspective: 1000px;
  animation: .7s cubic-bezier(.37, .05, .18, .94) both JK6uYG_shake;
  transform: translate3d(0, 0, 0);
}

@keyframes JK6uYG_shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(1.5px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}

body {
  overflow-y: scroll;
}

code[class*="language-"], pre[class*="language-"] {
  color: #000;
  text-shadow: 0 1px #fff;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  hyphens: none;
  background: none;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: 1em;
  line-height: 1.5;
}

pre[class*="language-"]::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

code[class*="language-"]::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*="language-"]::selection, pre[class*="language-"] ::selection, code[class*="language-"]::selection, code[class*="language-"] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*="language-"], pre[class*="language-"] {
    text-shadow: none;
  }
}

pre[class*="language-"] {
  margin: .5em 0;
  padding: 1em;
  overflow: auto;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: #f5f2f0;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .3em;
  padding: .1em;
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: #708090;
}

.token.punctuation {
  color: #999;
}

.token.namespace {
  opacity: .7;
}

.token.property, .token.tag, .token.boolean, .token.number, .token.constant, .token.symbol, .token.deleted {
  color: #905;
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  color: #690;
}

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string {
  color: #9a6e3a;
  background: #ffffff80;
}

.token.atrule, .token.attr-value, .token.keyword {
  color: #07a;
}

.token.function, .token.class-name {
  color: #dd4a68;
}

.token.regex, .token.important, .token.variable {
  color: #e90;
}

.token.important, .token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-origin: content-box;
  width: 20px;
  height: 20px;
  padding: 0 3px 3px 0;
  position: absolute;
}

.react-resizable-handle-sw {
  cursor: sw-resize;
  bottom: 0;
  left: 0;
  transform: rotate(90deg);
}

.react-resizable-handle-se {
  cursor: se-resize;
  bottom: 0;
  right: 0;
}

.react-resizable-handle-nw {
  cursor: nw-resize;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.react-resizable-handle-ne {
  cursor: ne-resize;
  top: 0;
  right: 0;
  transform: rotate(270deg);
}

.react-resizable-handle-w, .react-resizable-handle-e {
  cursor: ew-resize;
  margin-top: -10px;
  top: 50%;
}

.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-resizable-handle-n, .react-resizable-handle-s {
  cursor: ns-resize;
  margin-left: -10px;
  left: 50%;
}

.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}
/*# sourceMappingURL=index.3080ce25.css.map */
